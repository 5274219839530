import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, Type } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NzMessageDataOptions, NzMessageService } from 'ng-zorro-antd/message';
import { v4 as uuidV4 } from 'uuid';

import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { take } from 'rxjs';
import { ToastVirtualCommonComponent } from './toast-virtual-ref/toast-virtual-common.component';

export enum ToastAction {
  success = 'success',
  error = 'error',
  warning = 'warning',
  uploading = 'uploading',
  scanning = 'scanning',
  malicious = 'malicious'
}

export interface IToastValueType {
  scope?: string;
  toastId: string;
  action?: ToastAction;
}

export interface IToastCbPayloadType {
  uuid: string;
  action: ToastAction;
}

export interface IToastUpLoadParamsType {
  scope?: string;
  uuid?: string;
  msg?: string;
  options?: NzMessageDataOptions;
  checkHasToastId?: boolean;
  action?: ToastAction;
}

interface ICreateDynamicToastType {
  component: Type<ToastVirtualCommonComponent>;
  params: IToastUpLoadParamsType;
}

@Injectable()
export class ToastService {
  private viewContainerRef!: ViewContainerRef;
  private mapToastValue = new Map<string, IToastValueType>([]);
  private mapToastMessage = new Map<ToastAction, string>([
    [ToastAction.success, 'Uploaded successfully'],
    [ToastAction.error, 'There seems to be a little problem.'],
    [ToastAction.warning, 'Upload failed. Please retry.'],
    [ToastAction.uploading, 'Uploading'],
    [ToastAction.scanning, 'Scanning'],
    [ToastAction.malicious, 'Upload Blocked: The file is potentially unsafe.']
  ]);

  constructor(
    private message: NzMessageService,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  public setViewContainerRef(ref: ViewContainerRef): void {
    this.viewContainerRef = ref;
  }

  public createDynamicToast(payload: ICreateDynamicToastType): void {
    const { component, params } = payload;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = this.viewContainerRef?.createComponent(componentFactory);
    const action = params.action || ToastAction.success;
    componentRef.instance.action = action;
    componentRef.instance.msg = params.msg || '';
    componentRef.instance.createEvent.pipe(take(1)).subscribe(() => {
      this.common.create(
        {
          scope: params.scope,
          uuid: params.uuid || '',
          action
        },
        () => {
          return {
            objMessage: this.message.create(
              '',
              componentRef.instance.refToastVirtual || '',
              params.options
            ),
            componentRef
          };
        }
      );
    });
    // planB
    // const viewRef = this.viewContainerRef?.get(this.viewContainerRef?.length - 1) || {}
    // // @ts-ignore
    // // viewRef.toastServiceUUID = params.uuid;
  }

  get isMedium() {
    return this.breakpointObserver.isMatched('(min-width: 768px)');
  }

  public mat = {
    success: (msg: string) => {
      this.snackBar.open(msg, '', {
        panelClass: 'notification-success',
        verticalPosition: this.isMedium ? 'top' : 'bottom'
      });
    },
    info: (msg: string) => {
      this.snackBar.open(msg, '', {
        panelClass: 'notification-info',
        verticalPosition: this.isMedium ? 'top' : 'bottom'
      });
    },
    error: (msg: string) => {
      this.snackBar.open(msg, '', {
        panelClass: 'notification-error',
        verticalPosition: this.isMedium ? 'top' : 'bottom'
      });
    },
    alert: (msg: string) => {
      this.snackBar.open(msg, '', {
        panelClass: 'notification-alert',
        verticalPosition: this.isMedium ? 'top' : 'bottom'
      });
    }
  };

  public antd = {
    success: (msg: string, options?: NzMessageDataOptions | undefined) => {
      this.message.success(msg, options);
    },
    info: (msg: string, options?: NzMessageDataOptions | undefined) => {
      this.message.info(msg, options);
    },
    error: (msg: string, options?: NzMessageDataOptions | undefined) => {
      this.message.error(msg, options);
    },
    warning: (msg: string, options?: NzMessageDataOptions | undefined) => {
      this.message.warning(msg, options);
    },
    loading: (msg: string, options?: NzMessageDataOptions | undefined) => {
      this.message.loading(msg, options);
    }
  };

  public common = {
    success: (params?: IToastUpLoadParamsType) => {
      const {
        scope,
        uuid = uuidV4(),
        msg = this.mapToastMessage.get(ToastAction.success),
        options,
        checkHasToastId = true
      } = params || {};

      const { toastId } = this.mapToastValue.get(uuid) || {};
      if (checkHasToastId && params?.uuid && !toastId) {
        return uuid;
      }

      this.createDynamicToast({
        component: ToastVirtualCommonComponent,
        params: {
          scope,
          uuid,
          msg,
          options,
          checkHasToastId,
          action: ToastAction.success
        }
      });
      return uuid;
    },
    error: (params?: IToastUpLoadParamsType) => {
      const {
        scope,
        uuid = uuidV4(),
        msg = this.mapToastMessage.get(ToastAction.error),
        options,
        checkHasToastId = true
      } = params || {};

      const { toastId } = this.mapToastValue.get(uuid) || {};
      if (checkHasToastId && params?.uuid && !toastId) {
        return uuid;
      }

      this.createDynamicToast({
        component: ToastVirtualCommonComponent,
        params: {
          scope,
          uuid,
          msg,
          options,
          checkHasToastId,
          action: ToastAction.error
        }
      });
      return uuid;
    },
    warning: (params?: IToastUpLoadParamsType) => {
      const {
        scope,
        uuid = uuidV4(),
        msg = this.mapToastMessage.get(ToastAction.warning),
        options,
        checkHasToastId = true
      } = params || {};

      const { toastId } = this.mapToastValue.get(uuid) || {};
      if (checkHasToastId && params?.uuid && !toastId) {
        return uuid;
      }

      this.createDynamicToast({
        component: ToastVirtualCommonComponent,
        params: {
          scope,
          uuid,
          msg,
          options,
          checkHasToastId,
          action: ToastAction.warning
        }
      });
      return uuid;
    },
    uploading: (params?: IToastUpLoadParamsType) => {
      const {
        scope,
        uuid = uuidV4(),
        msg = this.mapToastMessage.get(ToastAction.uploading),
        options = { nzDuration: 0 },
        checkHasToastId = true
      } = params || {};

      const { toastId } = this.mapToastValue.get(uuid) || {};
      if (checkHasToastId && !toastId) {
        return uuid;
      }

      this.createDynamicToast({
        component: ToastVirtualCommonComponent,
        params: {
          scope,
          uuid,
          msg,
          options,
          checkHasToastId,
          action: ToastAction.uploading
        }
      });
      return uuid;
    },
    scanning: (params?: IToastUpLoadParamsType) => {
      const {
        scope,
        uuid = uuidV4(),
        msg = this.mapToastMessage.get(ToastAction.scanning),
        options = { nzDuration: 0 },
        checkHasToastId = false
      } = params || {};

      this.createDynamicToast({
        component: ToastVirtualCommonComponent,
        params: {
          scope,
          uuid,
          msg,
          options,
          checkHasToastId,
          action: ToastAction.scanning
        }
      });
      return uuid;
    },
    malicious: (params?: IToastUpLoadParamsType) => {
      const {
        scope,
        uuid = uuidV4(),
        msg = this.mapToastMessage.get(ToastAction.malicious),
        options,
        checkHasToastId = true
      } = params || {};

      const { toastId } = this.mapToastValue.get(uuid) || {};
      if (checkHasToastId && params?.uuid && !toastId) {
        return uuid;
      }

      this.createDynamicToast({
        component: ToastVirtualCommonComponent,
        params: {
          scope,
          uuid,
          msg,
          options,
          checkHasToastId,
          action: ToastAction.malicious
        }
      });
      return uuid;
    },
    create: (params: IToastUpLoadParamsType, cb?: any) => {
      const { scope, uuid = '', action } = params || {};
      if (!uuid) {
        return;
      }
      this.common.remove(uuid);

      // create component
      const { objMessage, componentRef } = cb();
      const toastId = objMessage.messageId;
      this.mapToastValue.set(uuid, {
        scope,
        toastId,
        action
      });

      objMessage.onClose.subscribe(() => {
        this.common.remove(uuid);
        // destroy component
        // this.viewContainerRef?.clear();
        componentRef.destroy();
      });
    },
    remove: (uuid: string) => {
      if (!uuid) {
        return;
      }
      const { toastId } = this.mapToastValue.get(uuid) || {};
      if (toastId) {
        this.message.remove(toastId);
        this.mapToastValue.delete(uuid);
      }
    },
    clear: () => {
      this.message.remove();
      this.mapToastValue.clear();
      // this.viewContainerRef?.clear();
    },
    clearCb: (
      params?: {
        scopes?: string[];
        uuids?: string[];
        actions?: ToastAction[];
      },
      cb?: (payload: IToastCbPayloadType) => void
    ) => {
      const { scopes = [], uuids = [], actions = [] } = params || {};
      this.mapToastValue.forEach((value, uuid) => {
        const { scope = '', toastId, action = ToastAction.success } = value || {};
        if (scopes.length && !scopes.includes(scope)) {
          return;
        }
        if (uuids.length && !uuids.includes(uuid)) {
          return;
        }
        if (actions.length && !actions.includes(action)) {
          return;
        }
        this.message.remove(toastId);
        this.mapToastValue.delete(uuid);
        cb &&
          cb({
            uuid,
            action
          });
      });
      // this.viewContainerRef?.clear();
    }
  };
}
